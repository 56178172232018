import "./styles.css";
import { createRoot } from "react-dom/client";
import { useState, useMemo } from "react";


function App() {
  const [currentRoom, setCurrentRoom] = useState("default");

  return (
    <main>
      Hello Partykit
    </main>
  );
}

createRoot(document.getElementById("app")!).render(<App />);
